//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  watch: {
    $route() {
      const cursor = this.$refs.followerCursor
      cursor.classList.remove('active')
    },
  },
}
